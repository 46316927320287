import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Descriptions,
  Empty,
  Row,
  Tag,
  Tooltip,
  message
} from 'antd';
import clsx from 'clsx';
import { keys, map, omit } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { Timezone, TrophyInHand } from '../../../assets/svg';
import {
  BREAKPOINTS,
  DATETIMEWITHDIVIDE,
  DEFAULTDATEFORMAT,
  DEFAULT_PAGE_SIZE,
  INS_STATUS_CLASSNAME,
  INS_STATUS_KEYS,
  INS_STATUS_LABEL,
  ISSUE_FILTER,
  ROUTES
} from '../../../common/constants';
import { titleCase } from '../../../common/utils';
import CollapsibleFilterWrapper from '../../../components/CollapsibleFilterWrapper';
import CommonCard from '../../../components/CommonCard';
import CommonDropdown from '../../../components/CommonDropdown';
import CommonSelect from '../../../components/CommonSelect';
import CommonTable from '../../../components/CommonTable';
import EllipsisText from '../../../components/EllipsisText';
import InfiniteScrollHandler from '../../../components/InfiniteScrollHandler';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import {
  GET_INSTRUCTION_LIST,
  INSTRUCTION_OTHER_TYPE_LIST,
  INSTRUCTION_TAG_LIST
} from '../graphql/Queries';
import Filters from './Filters';

const { Option } = CommonSelect;

const DashboardList = () => {
  const history = useHistory();
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;
  const tenantRecord = history?.location?.state?.tenantRecord || [];
  const projectRecord = history?.location?.state?.projectRecord || [];
  const initialProjectFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'createdAt', order: 'DESC' }
  };
  const initialPaginationValue = {
    total: 0,
    current: 1
  };
  const [paginationProp, setPaginationProp] = useState(
    navPagination || initialPaginationValue
  );
  const [projectFilter, setProjectFilter] = useState(
    navFilter || initialProjectFilter
  );
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const isMobileViewport = useMedia(`(max-width: ${BREAKPOINTS.mobile}px)`);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [instructionListData, setInstructionListData] = useState([]);
  const [tenantId, setTenantId] = useState(projectFilter?.tenantId);
  const [instructionStatus, setInstructionStatus] = useState(
    projectFilter?.instructionStatus ||
      // eslint-disable-next-line no-undef
      JSON.parse(localStorage.getItem(ISSUE_FILTER))
  );
  const [type, setType] = useState(projectFilter?.type);
  const [projectId, setProjectId] = useState(projectFilter?.projectId);
  const [isOverdue, setIsOverdue] = useState(projectFilter?.isOverdue);
  const [selectedTenantRecord, setSelectedTenantRecord] = useState(
    tenantRecord
  );
  const [selectedProjectRecord, setSelectedProjectRecord] = useState(
    projectRecord
  );
  const [selectedTags, setSelectedTags] = useState(projectFilter?.tags);

  useEffect(() => {
    if (navFilter) {
      setProjectFilter(navFilter);
    }
    if (navFilter?.instructionStatus) {
      setInstructionStatus(navFilter?.instructionStatus);
    }
    if (navFilter?.isOverdue) {
      setIsOverdue(navFilter?.isOverdue);
    }
    if (navFilter?.tags?.length > 0) {
      setSelectedTags(navFilter?.tags);
    }
    if (navFilter?.projectId) {
      setProjectId(navFilter?.projectId);
    }
    if (navFilter?.type) {
      setType(navFilter?.type);
    }
    if (navFilter?.tenantId) {
      setTenantId(navFilter?.tenantId);
    }
    if (navPagination) {
      setPaginationProp(navPagination);
    }
  }, [navFilter, navPagination]);

  const [fetchInstructionList, { loading }] = useLazyQuery(
    GET_INSTRUCTION_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.contactInstructionList?.data || [];
        const pagination = {
          ...paginationProp,
          defaultPageSize: DEFAULT_PAGE_SIZE,
          total: res?.contactInstructionList?.total
        };
        if (scrollFlag) {
          const datalist = [...instructionListData, ...data];
          setInstructionListData(datalist);
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setInstructionListData(datalist);
        }
        setHasMore(!!data?.length);
        setPaginationProp(pagination);
      },
      onError() {
        if (isDesktopViewport) {
          setInstructionListData([]);
        }
      }
    }
  );
  useEffect(() => {
    fetchInstructionList({
      variables: { filter: { ...projectFilter } }
    });
    if (history?.location?.state) {
      // eslint-disable-next-line no-undef
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectFilter]);

  useEffect(() => {
    let newFilter = {
      ...projectFilter,
      instructionStatus,
      type,
      tags: selectedTags,
      projectId,
      tenantId,
      skip: projectFilter?.skip !== 0 ? projectFilter?.skip : 0
    };
    if (isOverdue) {
      newFilter.isOverdue = isOverdue;
    } else {
      newFilter = omit(newFilter, 'isOverdue');
    }
    setPaginationProp({
      ...paginationProp,
      current: paginationProp?.current !== 1 ? paginationProp?.current : 1
    });
    setProjectFilter(newFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId, instructionStatus, projectId, type, isOverdue, selectedTags]);

  const getStatus = (record) => {
    return (
      <Tag className={clsx(INS_STATUS_CLASSNAME[record?.status], 'status-tag')}>
        {INS_STATUS_LABEL[record?.status]}
      </Tag>
    );
  };
  const getDueDate = (record) => {
    const isOverDue = record?.isOverdue;
    return record?.isNotice || !record?.reminderDate ? (
      '-'
    ) : (
      <div className={isOverDue ? 'text-danger' : ''}>
        {isOverDue
          ? ` Due since ${moment(record?.reminderDate).from(moment(), true)}`
          : `Due in ${moment(record?.reminderDate).from(
              moment(record?.createdAt),
              true
            )}`}
        <div>
          {moment(record?.reminderDate)
            .tz(record?.project?.timeZone)
            .format(DATETIMEWITHDIVIDE)}
          <div className="d-flex align-center timezone-div">
            <Timezone title="timeZone" />
            {record?.project?.timeZone}
          </div>
        </div>
      </div>
    );
  };
  const getDueStatus = (record) => {
    const isOverDue = record?.isOverdue;
    if (record?.status === INS_STATUS_KEYS?.CLOSED) {
      return '-';
    }
    if (record?.reminderDate) {
      return isOverDue ? (
        <div className="text-danger">
          Due since
          {` ${moment(record?.reminderDate).from(moment(), true)}`}
        </div>
      ) : (
        <div>
          Due in
          {` ${moment(record?.reminderDate).from(
            moment(record?.createdAt),
            true
          )}`}
        </div>
      );
    }
    return '-';
  };
  const getUpdatedBy = (record) => {
    if (record?.status === INS_STATUS_KEYS.RESPONDED) {
      return <EllipsisText text={record?.responder?.name || '-'} />;
    }
    return <EllipsisText text={record?.modifier?.name || '-'} />;
  };
  const getTypeName = (record) => {
    return record?.type || '-';
  };
  const columns = [
    //! commented for future use.
    // {
    //   title: '#',
    //   key: 'id',
    //   render: (text, record, index) => {
    //     return (
    //       <div className="d-flex align-center">
    //         <span>{projectFilter?.skip + index + 1}</span>
    //         <span className="ml-5 d-flex align-center">
    //           {record?.status === INS_STATUS_KEYS.NOTICE && (
    //             <Tooltip title="Notice">
    //               <InfoIconDark height="20px" width="20px" />
    //             </Tooltip>
    //           )}
    //         </span>
    //       </div>
    //     );
    //   }
    // },
    {
      title: 'STATUS',
      key: 'status',
      render: (users, record) => getStatus(record)
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (text) => (
        <div>
          <EllipsisText className="fw-medium" text={text || '-'} />
        </div>
      )
    },
    {
      title: 'RAISED BY',
      key: 'updatedBy',
      dataIndex: 'updatedBy',
      render: (text, record) => getUpdatedBy(record)
    },
    {
      title: 'RAISED ON',
      key: 'createdAt',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (createdAt, record) => {
        return record?.createdAt ? (
          <div>
            {moment(record?.createdAt)
              .tz(record?.project?.timeZone)
              .format(DEFAULTDATEFORMAT)}
            <div className="d-flex align-center timezone-div">
              <Timezone title="timeZone" />
              {record?.project?.timeZone}
            </div>
          </div>
        ) : (
          '-'
        );
      }
    },
    {
      title: 'DEADLINE',
      key: 'reminderDate',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'reminderDate' && sortedInfo?.order,
      render: (dueDate, record) => getDueDate(record)
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'type' && sortedInfo?.order,
      render: (text, record) => getTypeName(record)
    },
    {
      title: 'TAGS',
      dataIndex: 'tags',
      key: 'tags',
      render: (tags) => (
        <div>
          {tags?.length > 0 ? tags?.map((tag) => <div>{`#${tag}`}</div>) : '-'}
        </div>
      )
    }
  ];
  const onSearchChange = async (value) => {
    setPaginationProp(initialPaginationValue);
    setProjectFilter({ ...projectFilter, skip: 0, search: value });
  };
  const handleRowClick = (record) => {
    if (record?.id) {
      history.push(`${ROUTES.ISSUES}/${record?.id}`, {
        navFilter: projectFilter,
        navPagination: paginationProp,
        tenantRecord: selectedTenantRecord,
        projectRecord: selectedProjectRecord,
        currentPath: history?.location?.pathname
      });
    }
  };

  const handleRefetch = () => {
    fetchInstructionList({
      variables: {
        filter: {
          ...projectFilter,
          skip: instructionListData?.length
        }
      }
    });
  };
  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setProjectFilter({
        ...projectFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
    } else {
      setProjectFilter({
        ...projectFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: { field: 'createdAt', order: 'DESC' }
      });
    }
  };

  const handleClearFilter = () => {
    setProjectId();
    setTenantId();
    setType();
    setSelectedTags([]);
    setInstructionStatus();
    setProjectFilter({ ...projectFilter, search: '' });
    setIsOverdue(false);
  };

  const EmptyState = !loading && (
    <Empty
      image={TrophyInHand}
      imageStyle={{
        height: 148
      }}
      description={
        <div>
          <div className="empty-text">You are up to date!</div>
          {(tenantId ||
            projectId ||
            isOverdue ||
            instructionStatus ||
            type ||
            projectFilter?.search) &&
          !loading ? (
            <div className="empty-text-secondary">
              Filter applied, no data found.
              <Button
                type="link"
                className="clear-filter-button"
                onClick={handleClearFilter}
              >
                Clear Filter.
              </Button>
            </div>
          ) : (
            <div className="empty-text-secondary">No data found.</div>
          )}
        </div>
      }
    />
  );

  return (
    <>
      <Portal portalId="add-search-projects">
        <CollapsibleFilterWrapper
          searchProps={{
            id: 'search-container-id ',
            className: `search-component ${
              projectFilter?.search && 'focused-search'
            }`,
            getData: onSearchChange,
            defaultValue: projectFilter?.search,
            name: 'issue'
          }}
          isOpen={
            !!(
              tenantId ||
              projectId ||
              isOverdue ||
              instructionStatus?.length > 0 ||
              type
            )
          }
          className={clsx(isDesktopViewport && 'issue-filters')}
        >
          <Row
            className="d-flex filter-search"
            justify={isDesktopViewport ? 'end' : 'start'}
            align="middle"
            wrap
            gutter={[8, 8]}
          >
            <Col span={!isDesktopViewport && 24}>
              <Checkbox
                onChange={(event) => {
                  setIsOverdue(event?.target?.checked);
                  setProjectFilter({ ...projectFilter, skip: 0 });
                  setPaginationProp({ ...paginationProp, current: 1 });
                }}
                checked={isOverdue}
              >
                Overdue
              </Checkbox>
            </Col>
            <Filters
              tenantFilter
              projectFilter
              tenantId={tenantId}
              setTenantId={(id) => {
                setTenantId(id);
                setProjectFilter({ ...projectFilter, skip: 0 });
                setPaginationProp({ ...paginationProp, current: 1 });
              }}
              projectId={projectId}
              setProjectId={(id) => {
                setProjectId(id);
                setProjectFilter({ ...projectFilter, skip: 0 });
                setPaginationProp({ ...paginationProp, current: 1 });
              }}
              selectedProjectRecord={selectedProjectRecord}
              selectedTenantRecord={selectedTenantRecord}
              setSelectedProjectRecord={setSelectedProjectRecord}
              setSelectedTenantRecord={setSelectedTenantRecord}
            />
            <Col span={isDesktopViewport ? null : 12}>
              <CommonDropdown
                placeholder="Type"
                className={`instruction-selector dropdown-width-auto ${
                  isDesktopViewport ? 'width-200' : 'width-percent-100'
                } ${type ? 'focused-filter' : ''} `}
                value={type}
                onChange={(value) => {
                  setType(value);
                  setProjectFilter({ ...projectFilter, skip: 0 });
                  setPaginationProp({ ...paginationProp, current: 1 });
                }}
                allowClear
                showSearch
                optionFilterProp="children"
                query={INSTRUCTION_OTHER_TYPE_LIST}
                responsePath="instructionOtherTypeList.data"
                valuePath="type"
                labelPath="type"
                optionKey="type"
                uniqueByLabel="type"
                dropdownMatchSelectWidth={false}
                placement="bottomRight"
              />
            </Col>
            <Col span={isDesktopViewport ? null : 12}>
              <CommonDropdown
                placeholder="Tag"
                mode="multiple"
                maxTagTextLength={5}
                className={clsx(
                  `instruction-selector dropdown-width-auto`,
                  isDesktopViewport ? 'width-200' : 'width-percent-100',
                  selectedTags?.length > 0 && 'focused-filter'
                )}
                value={selectedTags}
                onChange={(tag) => {
                  if (tag?.length <= 2) {
                    setSelectedTags(tag);
                    setProjectFilter({ ...projectFilter, skip: 0 });
                    setPaginationProp({ ...paginationProp, current: 1 });
                  } else if (tag?.length > 2) {
                    message.destroy();
                    message.error('Only 2 tags are allowed!');
                  }
                }}
                allowClear
                showSearch
                optionFilterProp="children"
                query={INSTRUCTION_TAG_LIST}
                responsePath="instructionTagList.data"
                valuePath="tag"
                labelPath="tag"
                optionKey="tag"
                uniqueByLabel="tag"
                dropdownMatchSelectWidth={false}
                placement="bottomRight"
                showArrow
              />
            </Col>
            <Col span={!isDesktopViewport && 12}>
              <CommonSelect
                mode="multiple"
                placeholder="Status"
                maxTagCount={isMobileViewport ? 1 : 2}
                className={clsx(
                  !isDesktopViewport && 'clear-icon-scale',
                  isDesktopViewport ? 'min-width-200' : 'width-percent-100',
                  instructionStatus?.length > 0 && 'focused-filter'
                )}
                showArrow
                allowClear
                value={instructionStatus}
                onChange={(value) => {
                  // eslint-disable-next-line no-undef
                  localStorage.setItem(ISSUE_FILTER, JSON.stringify(value));
                  setInstructionStatus(value);
                  setProjectFilter({ ...projectFilter, skip: 0 });
                  setPaginationProp({ ...paginationProp, current: 1 });
                }}
                maxTagPlaceholder={
                  <Tooltip
                    title={instructionStatus?.map((item, index) => {
                      return `${titleCase(item)}${
                        index < instructionStatus?.length - 1 ? ', ' : ''
                      }`;
                    })}
                  >
                    +
                    {isMobileViewport
                      ? instructionStatus?.length - 1
                      : instructionStatus?.length - 2}
                  </Tooltip>
                }
              >
                {map(keys(INS_STATUS_KEYS), (item) => (
                  <Option key={item} value={item}>
                    {INS_STATUS_LABEL[item]}
                  </Option>
                ))}
              </CommonSelect>
            </Col>
            {isDesktopViewport && (
              <Col>
                <SearchComponent
                  id="search-container-id "
                  className={clsx(
                    'search-component',
                    projectFilter?.search && 'focused-search'
                  )}
                  getData={onSearchChange}
                  defaultValue={projectFilter?.search}
                  name="issue"
                />
              </Col>
            )}
          </Row>
        </CollapsibleFilterWrapper>
      </Portal>
      <div className="pointer todo-list">
        {isDesktopViewport ? (
          <CommonTable
            rowClassName={(record) => {
              if (record?.isActive === false) return 'deactivated-color';
            }}
            columns={columns}
            data={instructionListData}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            loading={loading}
            rowKey={(obj) => obj?.id}
            locale={{ emptyText: EmptyState }}
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record)
              };
            }}
          />
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            dataLength={instructionListData?.length}
            skeletonRows={columns?.length - 3}
            loading={loading}
            emptyState={EmptyState}
          >
            {map(instructionListData, (record) => {
              return (
                <CommonCard
                  key={record?.id}
                  onClick={() => handleRowClick(record)}
                >
                  <Row justify="space-between" align="middle">
                    <Col>{getStatus(record)}</Col>
                    <Col>{getDueStatus(record)}</Col>
                  </Row>

                  <EllipsisText
                    className="fw-medium mt-10"
                    text={record?.name || '-'}
                  />
                  <Descriptions layout="vertical" column={2} className="mt-6">
                    <Descriptions.Item label="Raised By">
                      <div>{getUpdatedBy(record)}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Raised On">
                      <div>
                        {record?.createdAt ? (
                          <div>
                            {moment(record?.createdAt)
                              .tz(record?.project?.timeZone)
                              .format(DEFAULTDATEFORMAT)}
                            <div className="d-flex align-center timezone-div">
                              <Timezone title="timeZone" />
                              {record?.project?.timeZone}
                            </div>
                          </div>
                        ) : (
                          '-'
                        )}
                      </div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Deadline">
                      <div>{getDueDate(record)}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Type">
                      <div>{record?.type || '-'}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Tags">
                      <div>{record?.tags?.join(', ') || '-'}</div>
                      <div>
                        {record?.tags?.length > 0
                          ? record?.tags?.map((tag) => <div>{`#${tag}`}</div>)
                          : '-'}
                      </div>
                    </Descriptions.Item>
                  </Descriptions>
                </CommonCard>
              );
            })}
          </InfiniteScrollHandler>
        )}
      </div>
    </>
  );
};

export default DashboardList;
