import { useLazyQuery } from '@apollo/client';
import { Button, Form, Image, Input, message, Modal, Upload } from 'antd';
import { concat, filter, findIndex, get, includes, map, nth } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  ALLOWED_IMAGE_TYPES,
  DATETIMEWITHDIVIDE,
  ELEMENT_IDS,
  FILE_SIZE_20MB,
  MAX_FILES_COUNT
} from '../../common/constants';
import {
  addWatermark,
  compressImage,
  dataURLtoFile,
  fileUpload,
  formValidatorRules,
  getBase64
} from '../../common/utils';
import UpgradeModal from '../../components/UpgradeModal';
import { GET_URL_BY_KEY, GET_URLS } from './graphql/Queries';

const { required } = formValidatorRules;

const CommentModal = (props) => {
  const {
    showModal,
    setShowModal,
    data,
    stageItemData,
    setCommentData,
    commentData,
    setAdditionalComment,
    additionalComment,
    setIsAdditionalPoint,
    isAdditionalPoint = false
  } = props;

  const {
    getEqcStageHistoryWithoutAuth: { tenantId, projectId, eqc, eqcStage }
  } = data;
  const itemHistory =
    stageItemData || nth(data?.getEqcStageHistoryWithoutAuth?.itemHistory, 0);

  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [previewImage, setPreviewImage] = useState([]);
  const [showImageSizeError, setShowImageSizeError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [itemImageList, setItemImageList] = useState([]);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  let files;
  const { getAllContacts, getCurrentUser } = useContext(AppContext);
  const allContacts = getAllContacts();
  const currentUser = getCurrentUser();
  const currentTenantData = filter(
    allContacts,
    (val) => val?.tenantId === tenantId
  )?.[0];

  const handleCancel = () => {
    setShowModal(false);
    if (isAdditionalPoint) {
      setIsAdditionalPoint(false);
    }
  };

  const [getImagesSignedUrls, { data: images }] = useLazyQuery(GET_URL_BY_KEY, {
    fetchPolicy: 'network-only'
  });

  const updateState = (urls = []) => {
    const comment = form.getFieldValue('comment');
    if (isAdditionalPoint) {
      setAdditionalComment({
        remark: comment,
        assets: imageList ? concat(imageList, urls) : urls
      });
    } else if (!stageItemData) {
      setCommentData((oldData) => {
        return {
          eqcStageItems: oldData?.eqcStageItems,
          eqcStage: {
            eqcStageId: data?.getEqcStageHistoryWithoutAuth?.eqcStageId,
            stageHistoryId: data?.getEqcStageHistoryWithoutAuth?.id,
            generalComment: comment,
            assets: imageList ? concat(imageList, urls) : urls
          }
        };
      });
    } else {
      setCommentData((oldData) => {
        const eqcStageItemData = {
          eqcStageItemId: stageItemData?.eqcStageItemId,
          stageHistoryId: data?.getEqcStageHistoryWithoutAuth?.id,
          generalRemark: comment,
          assets: itemImageList ? concat(itemImageList, urls) : urls
        };
        const newStageItems = [...oldData?.eqcStageItems];
        const index = findIndex(
          newStageItems,
          ({ eqcStageItemId }) =>
            eqcStageItemId === stageItemData?.eqcStageItemId
        );
        if (index === -1) {
          newStageItems.push(eqcStageItemData);
        } else {
          newStageItems.splice(index, 1, eqcStageItemData);
        }
        return {
          eqcStage: oldData?.eqcStage,
          eqcStageItems: newStageItems
        };
      });
    }
    setDisabled(false);
    setShowModal(false);
  };
  const [fetchSignedUrl] = useLazyQuery(GET_URLS, {
    fetchPolicy: 'network-only',
    onCompleted: async (response) => {
      const filteredFileList = filter(fileList, 'name');
      const promises = map(
        response?.getSignedPutUrls?.signedUrls,
        async (imageUrl, index) => {
          const callBack = async (image) => {
            const watermarkedImageUrl = await addWatermark(image);
            const file = dataURLtoFile(
              watermarkedImageUrl,
              filteredFileList[index]?.originFileObj?.name
            );
            await fileUpload(imageUrl, file).catch(() => {
              return message.error('Image upload failed!');
            });
          };
          await compressImage({
            file: filteredFileList[index]?.originFileObj,
            fileType: filteredFileList[index]?.originFileObj?.type?.split(
              '/'
            )?.[1],
            callBack: callBack
          });
        }
      );
      await Promise.all(promises);
      const assets = map(response?.getSignedPutUrls?.keys, (key, index) => ({
        type: 'GENERAL_REMARK',
        key: key,
        assetType: 'IMAGE',
        size: fileList?.[index]?.size,
        isImageCaptured: false
      }));
      updateState(assets);
    }
  });

  const handleOnChange = (info) => {
    const newFileList = filter(info?.fileList, (file) => {
      setShowImageSizeError(false);
      if (file?.size > FILE_SIZE_20MB) {
        setShowImageSizeError(true);
        return false;
      }
      if (!file?.url && !includes(ALLOWED_IMAGE_TYPES, file?.type)) {
        message.destroy();
        message.error('File type should be PNG, JPG, JPEG');
        return false;
      }
      return file;
    });
    setFileList(newFileList);
  };
  const handleUploadImage = async () => {
    setDisabled(true);
    const fileSend = (anotherFileList) => {
      return map(anotherFileList, (file) => ({
        fileName: `inspection/tenant/${tenantId}/project/${projectId}/EQC/${eqc?.uuid}/stage/${eqcStage?.uuid}/general-remark/${file?.name}`,
        contentType: file.type
      }));
    };
    const filteredFileList = filter(fileList, 'name');
    if (filteredFileList?.length) {
      try {
        fetchSignedUrl({
          variables: {
            data: fileSend(filteredFileList)
          }
        });
      } catch (error) {
        setDisabled(false);
      }
    } else {
      updateState();
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      const preview = await getBase64(file?.originFileObj);
      files = file.url || preview;
    }
    setPreviewImage((oldData) => [...oldData, files]);
  };

  const defaultList = (imageArr) => {
    return map(imageArr, (image, index) => ({
      uid: index,
      url: image
    }));
  };

  useEffect(() => {
    if (images?.getImagesSignedUrls?.length > 0) {
      setFileList(defaultList(images?.getImagesSignedUrls));
    }
  }, [images]);

  useEffect(() => {
    if (!form.getFieldValue('comment')) {
      if (isAdditionalPoint) {
        const additionalCommentImage = map(additionalComment?.assets, `key`);
        if (additionalCommentImage?.length > 0) {
          getImagesSignedUrls({
            variables: {
              data: { keys: additionalCommentImage }
            }
          });
        }
        setFileList(defaultList(additionalCommentImage));
        setImageList(additionalComment?.assets);
        form.setFieldsValue({
          comment: additionalComment?.remark
        });
      } else if (!stageItemData) {
        const generalComment = get(commentData, `eqcStage.generalComment`);
        const generalCommentImage = map(commentData?.eqcStage?.assets, `key`);
        if (generalCommentImage?.length > 0) {
          getImagesSignedUrls({
            variables: {
              data: { keys: generalCommentImage }
            }
          });
        }
        setFileList(
          defaultList(generalCommentImage || itemHistory?.generalCommentImages)
        );
        setImageList(
          commentData?.eqcStage?.assets || itemHistory?.generalCommentImages
        );
        form.setFieldsValue({
          comment: generalComment || itemHistory?.generalComment
        });
      } else {
        const index = findIndex(
          commentData?.eqcStageItems,
          ({ eqcStageItemId }) =>
            eqcStageItemId === stageItemData?.eqcStageItemId
        );
        const generalRemark = get(
          commentData,
          `eqcStageItems[${index}].generalRemark`
        );
        const generalRemarkImages = map(
          commentData?.eqcStageItems[index]?.assets,
          `key`
        );
        if (generalRemarkImages?.length > 0) {
          getImagesSignedUrls({
            variables: {
              data: { keys: generalRemarkImages }
            }
          });
        }
        setFileList(
          defaultList(generalRemarkImages || itemHistory?.generalRemarkImages)
        );
        setItemImageList(
          commentData?.eqcStageItems[index]?.assets ||
            itemHistory?.generalRemarkImages
        );
        form.setFieldsValue({
          comment: generalRemark || itemHistory?.generalRemark
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentData, itemHistory?.generalComment]);

  const onFinish = async () => {
    await handleUploadImage();
  };

  const handleRemove = (info) => {
    const imageKey = info?.url?.split('/')?.[3]?.split('?')?.[0];
    if (isAdditionalPoint) {
      const fileIndex = findIndex(
        additionalComment?.assets,
        ({ file }) => file?.key === imageKey
      );
      additionalComment?.assets?.splice(fileIndex, 1);
      setImageList(additionalComment?.assets);
    } else if (stageItemData) {
      const index = findIndex(
        commentData?.eqcStageItems,
        ({ eqcStageItemId }) => eqcStageItemId === stageItemData?.eqcStageItemId
      );
      const fileIndex = findIndex(
        commentData?.eqcStageItems?.[index]?.assets,
        ({ file }) => file?.key === imageKey
      );
      commentData?.eqcStageItems?.[index]?.assets?.splice(fileIndex, 1);
      setItemImageList(commentData?.eqcStageItems?.[index]?.assets);
    } else {
      const fileIndex = findIndex(
        commentData?.eqcStage?.assets,
        ({ file }) => file?.key === imageKey
      );
      commentData?.eqcStage?.assets?.splice(fileIndex, 1);
      setImageList(commentData?.eqcStage?.assets);
    }
  };
  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      onCancel={handleCancel}
      closable={false}
      footer={null}
    >
      {
        //! giving inline style as need to generate image from this div
      }
      <div style={{ opacity: 0, height: 0 }}>
        <div
          id={ELEMENT_IDS?.TIME_STAMP}
          style={{
            background: '#000',
            color: '#3366ff',
            width: 'max-content'
          }}
        >
          {`${moment()
            .tz(data?.getEqcStageHistoryWithoutAuth?.project?.timeZone)
            .format(DATETIMEWITHDIVIDE)} - ${
            data?.getEqcStageHistoryWithoutAuth?.project?.timeZone
          }`}
        </div>
      </div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="comment"
          label="Comment"
          rules={[
            required,
            {
              max: 1000,
              message: 'Comment cannot be more than 1000 characters'
            },
            { whitespace: true }
          ]}
        >
          <TextArea />
        </Form.Item>
        <div className="mb-10 text-secondary">Images</div>
        <Upload
          maxCount={
            currentTenantData?.tenant?.inspectionAssetLimit || MAX_FILES_COUNT
          }
          accept=".png,.jpeg,.jpg"
          multiple
          listType="picture-card"
          fileList={fileList}
          onChange={(info) => {
            handleOnChange(info);
          }}
          beforeUpload={(e, ...rest) => {
            if (
              [...rest?.[0], ...fileList]?.length >
              currentUser?.tenant?.inspectionAssetLimit
            ) {
              setShowUpgradeModal(true);
            }
            return false;
          }}
          onPreview={(e) => {
            handlePreview(e);
          }}
          onRemove={(e) => handleRemove(e)}
          showUploadList={{ showPreviewIcon: false }}
        >
          <div
            className="height-percent-100 width-percent-100 d-flex align-center justify-center pointer"
            onClick={(e) => {
              if (
                fileList?.length ===
                (currentTenantData?.tenant?.inspectionAssetLimit ||
                  MAX_FILES_COUNT)
              ) {
                e.stopPropagation();
                setShowUpgradeModal(true);
              }
            }}
          >
            <Button type="primary" shape="round">
              Add
            </Button>
          </div>
        </Upload>
        <div>
          {React.Children.map(previewImage, (photo) => (
            <Image alt="comment-image" src={photo} />
          ))}
        </div>
        {showImageSizeError && (
          <div className="mt-10 mb-10 text-danger">
            * Maximum file size is 20MB
          </div>
        )}
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            className="save-button"
            htmlType="submit"
            disabled={disabled}
            type="primary"
          >
            {disabled ? 'Uploading...' : 'Save'}
          </Button>
        </div>
      </Form>
      {showUpgradeModal && (
        <UpgradeModal
          showModal={showUpgradeModal}
          setShowModal={setShowUpgradeModal}
          projectId={projectId}
          featureKey="INSPECTION_ASSET_LIMIT"
          tenantId={tenantId}
          isAlreadyRequested={
            currentTenantData?.tenant?.featureApprovalRequestConfig
              ?.inspectionAssetLimitRequestSent
          }
        />
      )}
    </Modal>
  );
};

export default CommentModal;
